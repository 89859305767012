@import "~antd/dist/antd.css";

.app-layout {
    display: grid;
    grid-template-areas: 
        'header'
        'content';
    grid-template-columns: auto;
    gap: 20px;
    padding-bottom: 20px;
}

.header-layout {
    grid-area: header;
    color: whitesmoke;
    text-align: center;
    line-height: 1;
    background-color: rgb(124, 124, 124);
}

.header-layout h1 {
    color: whitesmoke;
    font-size: 52px;
    text-align: center;
    background-color: rgb(124, 124, 124);
}

.header-grid-layout {
    display: grid;
    grid-template-columns: 150px auto;
    grid-template-rows: auto;
    align-items: center;
    column-gap: 10px;
}

.home-button {
    font-size: 6vmin;
    padding-left: 20px;
}

.content-layout {
    grid-area: content;
    display: grid;
    grid-template-rows: 1fr 3fr;
    max-height: 1000px;
    padding-bottom: 20px;
}

.content-layout h2 {
    color: whitesmoke;
    font-size: 6vmin;
    line-height: 120%;
    background-color: rgb(124, 124, 124);
}

.content-layout h3 {
    font-size: 6vmin;
    line-height: 120%;
}

.search-section {
    display: grid;
    grid-template-rows: auto 1fr;
    max-height: 900px;
    padding-bottom: 20px;
}

.search-input {
    display: grid;
    grid-template-columns: auto;
    gap: 4px;
    font-size: 18px;
    padding-left: 10px;
}

.search-title {
    font-size: 18px;
    padding-left: 10px;
}

.data-upload-grid {
    grid-area: upload;
    display: grid;
    grid-template-areas: 
        'DataInput ImageInput'
        'UploadButton UploadButton';
    grid-template-rows: auto auto auto; 
    gap: 20px;
}

.data-input-fields-grid {
    grid-area: DataInput;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 20px;
    row-gap: 10px;
    padding-left: 20px;
}

.title-Name {
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 28px; 
    text-align: left;
    padding-left: 10px;
}

.image-input-field {
    grid-area: ImageInput;
    text-align: left;
}

.upload-button {
    grid-area: UploadButton;    
}

.image-upload-grid {
    display: grid;
    grid-template-columns: auto;
    padding: 10px;
}
  
.image-upload-grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;
    align-content: center;
}

.app-text-input-field {
    max-width: 500px;
    font-size: 120%;
}

.leftRightSplit {
    display: inline-grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 10px;
    padding: 10px;
    max-height: 900px;
    padding-bottom: 20px;
}

.leftRightSplit > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}

.itemLeftScroll { 
    overflow: scroll;
    max-height: 700px;
    padding-bottom: 20px;
}

.itemRightDetails { 
    position: relative;
    top: 0;
    right: 0;
    max-height: 900px;
    padding-bottom: 20px;

}

.stick-right {
    right: 2%;
    top: 4%;
    border: 3px solid rgba(0, 0, 0, 0.8);
}

.data-input-fields-gridDetails {
    grid-area: DataInput;
    display: grid;
    grid-template-columns: 1fr minmax(50px, 3fr);
}

.data-input-fields-grid > div {
    text-align: left;
}

.app-objects {
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: 'auto';
    grid-template-rows: '100px';
    padding: 10px;
}

.overview-image-style {
    object-fit: contain;
}

.cardOverview-layout {
    text-align: center;
    width: 150px;
    height: 150px;
    align-content: center;
}

.cardOverview-layout:hover{
    outline: 1px solid rgba(99, 99, 99, 0.8);
}

.cardOverview-layout-border {
    text-align: center;
    /* width: 100px; */
    width: 150px;
    height: 150px;
    align-content: center;
    outline: 2px solid rgba(0, 0, 0, 0.8);
    padding-bottom: 8px;
}

.cardDetails-layout {
    display: grid;
    grid-area: editableLabels;
    grid-row-gap: 1px;
    grid-template-rows: '50px auto';
    padding: 10px;
    text-align: left;
    /* font-size: 80%; */
}
  
.label-input {
    font-size: 13px;
    padding-left: 10px;
    text-align: left;  
}

.text-input {
    font-size: 13px;
    padding-left: 10px;
    text-align: left;
}

.row-layout-card-itemOverview {
    padding: 1%;
    font-size: 80%; 
    text-align: left;
}

.row-layout-card-itemDetails {
    /* /* padding: 1%; */
    /* font-size: 50%;  */
    /* text-align: left; */ 
}

.app-text-input-name {
    /* font-size: 60%; */
    text-align: center;
}

.image-gallery {
    display: block;
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.App-add-image-button {
    min-width: 100px;
    min-height: 100px;
    background-color: transparent;
}

.card-item-title {
    font-size: 120%; 
    text-align: left;
    align-content: flex-start;
    /* max-width: 300px; */
    /* min-height: 60px; */
    /* padding-bottom: 20px; */
}
/* .row-layout-card {
    display: grid;
    grid-row-gap: 0px;
    grid-template-columns: auto auto;
    padding: 0px;
    text-align: start;
} */

/*
.app-layout>div {
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}


.App {
    text-align: center;
}

.App-header h1 {
    font-size: 14vmin;
    line-height: 120%;
    text-align: center;
    background-color: crimson;
}

.objectTitle h1 {
    color: rgb(0, 0, 0);
    font-size: 4vmin;
    line-height: 120%;
}

.image-upload-area {
}

.App-content {
    position: relative;
    align-self: center;
}

.App-text-input-field {
    max-width: 800px;
    margin-right: 5px;
    font-size: 160%;
}

.Editable-Labels {
    white-space: pre-line;
}

.App-optional-text-input-field {
    max-width: 300px;
    margin-right: 5px;
    font-style: italic;
}

.App-input-field {
    max-width: 300px;
    margin-right: 5px;
    
}

.App-optional-text-input-name {
    font-style: italic;
    font-size: 60%;
}


.App-add-image-input {
    max-width: 300px;
    margin-right: 5px;
}



.App-todos {
    background-color: white;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.App-todo {
}

.App-todo-complete {
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    right: 24px;
}

.App-object-title {
    font-size: 22px;
    font-weight: bold;
}

.image-upload-header-text {
    color: whitesmoke;
    font-size: 4vmin;
    line-height: 120%;
    background-color: grey;
}


.App-add-image-button {
}

.App-add-image-upload-button {
    background-color: transparent;
}


.editableLabels {
    background-color: "white";
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 1px;
    font-size: 50%;
    text-align: start;
    align-content: start;
}






.uploadedImageContainer {
    height: 200px;
    position: relative;
    border: 3px solid green;
}

.uploadImageCenter {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.Hidden {
    display: none;
}  */